<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5121 17.1396L17.6529 9.99886L16.3833 8.74831L10.5121 14.6195L7.56591 11.6733L6.32133 12.9239L10.5121 17.1396ZM11.9999 22.6494C10.6088 22.6494 9.29591 22.3837 8.06136 21.8524C6.82681 21.3211 5.74853 20.5945 4.82653 19.6725C3.90453 18.7505 3.17788 17.6725 2.64656 16.4385C2.11526 15.2045 1.84961 13.8919 1.84961 12.5008C1.84961 11.093 2.11526 9.77178 2.64656 8.53723C3.17788 7.30268 3.90422 6.22847 4.82558 5.31458C5.74697 4.40072 6.82481 3.67723 8.05911 3.14413C9.29339 2.61103 10.6063 2.34448 11.9977 2.34448C13.4059 2.34448 14.7274 2.61088 15.9625 3.14368C17.1975 3.67647 18.2718 4.39953 19.1854 5.31288C20.0989 6.22623 20.8222 7.30026 21.3551 8.53496C21.888 9.76967 22.1545 11.0913 22.1545 12.4999C22.1545 13.8916 21.888 15.2047 21.3549 16.4392C20.8218 17.6737 20.0983 18.7517 19.1844 19.6732C18.2705 20.5947 17.1966 21.3211 15.9626 21.8524C14.7286 22.3837 13.4077 22.6494 11.9999 22.6494Z"
      fill="#84FAE4"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcRoundChecked',
}
</script>
